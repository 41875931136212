import { useEffect, PropsWithChildren, useState } from 'react'

import { appsApi, facadeApi, userDetailsApi, workspacesApi, recentWorkApi, newsApi, rndApi, calendarApi } from 'api'
import { useStableCallback } from 'hooks/useStableCallback'
import { useAuth } from 'providers/auth/AuthContext'

const apiInstances = [userDetailsApi, workspacesApi, appsApi, facadeApi, recentWorkApi, newsApi, rndApi, calendarApi]

export const ProtectedApiProvider = ({ children }: PropsWithChildren<{}>) => {
  const [isReady, setIsReady] = useState(false)
  const { jwt } = useAuth()

  const getJwt = useStableCallback(() => jwt)

  useEffect(() => {
    const interceptors = apiInstances.map(instance =>
      instance.client.interceptors.request.use(
        config => {
          const token = getJwt()

          if (token) {
            config!.headers!.Authorization = `Bearer ${token}`
          }

          return config
        },
        error => Promise.reject(error),
      ),
    )

    setIsReady(true)

    return () => {
      interceptors.forEach((interceptor, index) => {
        apiInstances[index].client.interceptors.request.eject(interceptor)
      })
    }
  }, [getJwt])

  return <>{isReady && children}</>
}
