import { useEffect, useState } from 'react'

interface DelayUnmountProps {
  children: React.ReactNode
  delayTime?: number
  show?: boolean
}

export default function DelayUnmount({ children, delayTime = 0, show = true }: DelayUnmountProps) {
  const [shouldRender, setShouldRender] = useState(show)

  useEffect(() => {
    if (show) {
      setShouldRender(true)
    } else {
      const timeout = setTimeout(() => {
        setShouldRender(false)
      }, delayTime)
      return () => clearTimeout(timeout)
    }
  }, [delayTime, shouldRender, show])

  return <>{shouldRender ? children : null}</>
}
